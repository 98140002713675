.container {
    width: 100%;
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: normal;

    .qrHeader {
        position: static;
        height: 38px;
        left: 5.33%;
        right: 5.33%;
        top: calc(50% - 38px/2 + 21px);
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #B3B7C7;
        text-shadow: -1px -1px 0px #000000;
        flex: none;
        order: 1;
        flex-grow: 1;
        margin: 10px 0px;
    }

    .qrCard {
        display: flex;
        flex-direction: column;
        padding: 10px;
        background: #EFF2F7;
        box-shadow: 10px 0px 20px #000000;
        border-radius: 10px;
    }

    .formFieldQr,
    .PrivateNotchedOutline-root-12 {
        top: -5px;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: inset -7px -7px 10px rgba(255, 255, 255, 0.5), inset 7px 7px 10px rgba(176, 179, 195, 0.25);
        border-radius: 15px;
        align-self: stretch;
        margin: 10px 0px;
    }

    .formLoader {
        position: absolute;
        background: rgba(255, 255, 255, 0.3);
        height: 100%;
        width: 100%;
        top: 0%;
        left: 0%;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        align-self: center;
    }

    .location-search-input,
    .input-text {
        width: 100%;
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #91A5C6;
        padding: 1px;
        border: none;
        background-color: #EFF2F7;
        outline: none;
    }

    .fullWidth {
        width: 100%;
    }

    .submit {
        align-items: center;
        height: 44px;
        margin: 0 auto;
        background: #40CC52;
        box-shadow: -8px -8px 8px #FFFFFF, 5px 5px 8px #CBD9F0;
        border-radius: 10px;
        flex-grow: 0;
        margin: 20px auto;
    }

    .qrForm {
        margin: 0 auto;
        width: 90%;
    }

    .rdn-drop-menu-option {
        background-color: white;
    }

    .location-search-input::placeholder,
    textarea::placeholder,
    input::placeholder {
        font-family: 'Nunito Sans', sans-serif;
        font-style: normal;
        font-weight: normal;
    }

    .dropdownPickUp {
        background-color: #EFF2F7;
        border-color: transparent;

        .rdn-control-arrow-icon {
            border-color: #EFF2F7;
            border-style: solid;
            border-width: 5px 5px 0;
            content: ' ';
            display: block;
            height: 0;
            margin-top: -ceil(2.5);
            position: absolute;
            right: 10px;
            top: 14px;
            width: 0;
        }
    }

    span {
        color: #91A5C6;
        font-style: normal;
        font-weight: 400;
        height: 20px;
    }

    .left {
        display: block;
        float: left;
        width: 70px;
    }

    .right {
        display: block;
        float: right;
        width: 200px;
        text-align: right;
    }

    .nameError {
        color: red;
    }

    .estimateGrid {
        margin: 10px 0;
        padding-top: 10px;
        text-align: center;
        background-color: #EFF2F7;
        border-color: transparent;
        box-shadow: inset -7px -7px 10px rgba(255, 255, 255, 0.5), inset 7px 7px 10px rgba(176, 179, 195, 0.25);
        border-radius: 15px;

        .estimateGridTitle {
            font-weight: 600;
            font-size: 16px;
            text-align: center;
        }

        .estimateColTitle {
            font-weight: 400;
            font-size: 15px;
            text-align: center;
        }

        .estimateColValue {
            font-weight: 600;
            font-size: 15px;
            text-align: center;
        }
    }
}

.qrFormBody {
    margin: 0 auto;
    height: 100em;
}

.Icon {
    width: 24px;
    height: 24px;
}

.MuiInput-underline:before {
    border: 0px !important;
}

.MuiInput-underline::after {
    border: 0px !important;
    background-color: transparent !important;
}

.MuiInput-underline::shadow {
    border: 0px !important;
    background-color: transparent !important;
}